import AlertIcon from 'assets/icons/alertIcon';
import WrenchIcon from 'assets/icons/wrenchIcon';
import { UiCloseButton } from 'components/uiKit/buttons/CloseButton';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getSessionStorage, setSessionStorage } from 'utils/functions/sessionStorate';
import { CmsPost } from 'utils/globalTypes';
import { useQueryDefaultSetting } from 'utils/services/api';
import { getPostBySlug } from 'utils/services/api/requests/cms';
interface TopMessageProps {
  onMessageShow?: (value: string | boolean) => void;
  messageSlug?: string;
  className?: string;
}
const CLOSED_NOTIFICATION = 'CLOSED_NOTIFICATION';
export const TopMessage = ({
  onMessageShow,
  className,
  messageSlug = 'maintenance-message'
}: TopMessageProps) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const {
    data
  } = useQuery([messageSlug], () => getPostBySlug(messageSlug), {
    ...useQueryDefaultSetting
  });
  const msg = data?.docs?.find?.((post: CmsPost) => {
    const startTime = new Date(post.published.startTime).toLocaleDateString();
    const endTime = new Date(post.published.endTime).toLocaleDateString();
    const today = new Date().toLocaleDateString();
    return startTime <= today && today <= endTime;
  });
  const replaced = [{
    code: '{date}',
    value: new Date(msg?.published?.startTime).toLocaleDateString()
  }, {
    code: '{time}',
    value: '00:00:00'
  }];
  const replacedText = replaced?.reduce((str: string, item: {
    code: string;
    value: string;
  }) => {
    if (item) {
      return str?.replace(item.code, item.value);
    }
    return str;
  }, msg?.description_html) ?? msg?.description_html;
  useEffect(() => {
    const dismissedMsg = getSessionStorage([CLOSED_NOTIFICATION, messageSlug].join('_'));
    setIsDismissed(!!dismissedMsg);
    if (replacedText && !dismissedMsg) {
      onMessageShow?.(true);
    } else {
      onMessageShow?.(false);
    }
  }, [replacedText]);
  const onCloseHandler = () => {
    setSessionStorage([CLOSED_NOTIFICATION, messageSlug].join('_'), messageSlug, 1);
    setIsDismissed(true);
    onMessageShow?.(false);
  };
  return !isDismissed && replacedText ? <div className={`bg-secondary w-full p-4 text-center text-primary flex items-center md:justify-center relative ${className}`}>
      <UiCloseButton className="absolute top-2 right-2 cursor-pointer w-3 h-3" onClick={onCloseHandler} />
      {msg.title.includes('Alert') ? <AlertIcon className="h-6 w-6 mr-3" /> : <WrenchIcon className="h-6 w-6 mr-3" />}
      <p className="leading-[15px] xs:text-left md:text-center md:text-base xs:text-14 md:w-auto xs:w-[calc(100%-24px)]" dangerouslySetInnerHTML={{
      __html: replacedText
    }} />
    </div> : null;
};