import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { useContext, useEffect, useState } from 'react';
import { formatCurrencySymbol } from 'utils/functions/wallet';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import { UiCloseButton } from 'components/uiKit/buttons/CloseButton';
import Zimpler from 'assets/logo/zimpler';
import GlobalContext from 'context/global';
import { parseAmountValue } from 'utils/functions/ui/format';
import { DepositSectionContext } from '..';

// Constants
const values = [40, 80, 200];
const currency = 'EUR';
export default function AmountForm() {
  const {
    value,
    setValue,
    setStep,
    boundaries
  } = useContext(DepositSectionContext);
  const {
    handleGoBack,
    appType
  } = useContext(GlobalContext);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseAmountValue(event.target.value));
  };
  const parsedValue = parseFloat(value);
  const isDisable = Number.isNaN(parsedValue) || parsedValue < (boundaries?.minDeposit ?? 10) || parsedValue >= (boundaries?.maxDeposit ?? 1500);
  const [isPrice, setIsPrice] = useState<'insufficient' | 'valid' | 'invalid' | ''>('');
  useEffect(() => {
    if (Number(value) < (boundaries?.minDeposit || 10) || Number(value) >= (boundaries?.maxDeposit || 1500)) {
      setIsPrice('invalid');
    } else {
      setIsPrice('valid');
    }
  }, [value]);
  const getStatus = () => {
    if (!value) {
      return '';
    }
    if (isPrice === 'invalid' || isPrice === 'insufficient') {
      return 'error';
    }
    return 'success';
  };
  return <Grid className="rounded-2xl border-none h-[38.5rem] max-w-[calc(100%-2rem)] bg-white text-primary relative overflow-hidden xs:h-fit" padding="p-2" gap="1rem" horizontalAlgin="center" verticalAlgin="space-between" responsiveWidth={{
    sm: 100,
    md: '42.2rem'
  }} data-sentry-element="Grid" data-sentry-component="AmountForm" data-sentry-source-file="amountForm.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
        <Grid horizontalAlgin="flex-end" padding="p-3" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
          <UiCloseButton className="md:absolute top-0 right-0" size={appType === 'desktop' ? 'md' : 'sm'} onClick={() => handleGoBack()} variant="dark" data-sentry-element="UiCloseButton" data-sentry-source-file="amountForm.tsx" />
        </Grid>
        <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
          <Typography style={{
          fontWeight: '700',
          fontSize: '40px'
        }} algin="center" translateGroup="form-deposit" translateKey="how-much-to-deposit" data-sentry-element="Typography" data-sentry-source-file="amountForm.tsx" />
        </Grid>
        <Grid padding="p-4" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
          <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
            <Typography style={{
            fontSize: '20px',
            maxWidth: '400px'
          }} algin="center" translateGroup="form-deposit" translateKey="chose-amount-to-proceed" data-sentry-element="Typography" data-sentry-source-file="amountForm.tsx" />
          </Grid>
        </Grid>
        <Grid padding="pt-5" horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
          <Grid style={{
          maxWidth: '300px'
        }} verticalAlgin="flex-start" gap="0.75rem" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
            <Grid wrap="nowrap" gap="calc(14 / 16 * 1rem)" verticalAlgin="flex-start" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
              {values.map(btnValue => <Button key={`banner-form-btn-${btnValue}`} id={`banner-form-btn-${btnValue}`} block color={value === parseAmountValue(btnValue).toString() ? 'outline-primary' : 'disable'} onClick={() => setValue(parseAmountValue(btnValue).toString())} style={{
              height: '47px'
            }}>
                  <Typography style={{
                whiteSpace: 'nowrap',
                fontSize: '20px'
              }}>
                    {formatCurrencySymbol(btnValue, currency)}
                  </Typography>
                </Button>)}
            </Grid>
            <InputGroup id="value" name="value" label="Valitse oma summa" value={value} inputType="number" dataInput="true" status={getStatus()} feedback={isPrice === 'invalid' && value && <Typography translateGroup="limit-value-feedback" translateKey={`rang-valid-${boundaries?.minDeposit || 10}-${boundaries?.maxDeposit || 1500}`} weight={600} />} inputProps={{
            placeholder: 'Valitse oma summa',
            style: {
              textAlign: 'center',
              fontSize: 'x-large',
              height: '60px',
              color: 'var(--primary)'
            },
            inputMode: 'decimal'
          }} onChange={handleInputChange} onFocus={() => {
            setValue('');
          }} data-sentry-element="InputGroup" data-sentry-source-file="amountForm.tsx" />
            <Button disabled={isDisable} id="banner-form-submit" color="yellow" block style={{
            height: '60px'
          }} onClick={() => setStep('loading-cashier')} data-sentry-element="Button" data-sentry-source-file="amountForm.tsx">
              <Grid horizontalAlgin="center" gap="0.5rem" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
                <Typography style={{
                fontSize: '20px'
              }} translateGroup="home-banner-form" translateKey="TALLETA" weight={700} data-sentry-element="Typography" data-sentry-source-file="amountForm.tsx" />
                <Typography style={{
                fontSize: '20px'
              }} translateGroup="home-banner-form" translateKey={formatCurrencySymbol(Number(value), currency)} weight={700} data-sentry-element="Typography" data-sentry-source-file="amountForm.tsx" />
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
        <Grid style={{
        maxWidth: '535px'
      }} data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
          <Typography translateGroup="form-deposit" translateKey="i-accept-the-therms-and-privacy-policy" size="md" algin="center" color="#000" data-sentry-element="Typography" data-sentry-source-file="amountForm.tsx" />
        </Grid>
        <Grid wrap="nowrap" style={{
        maxWidth: '535px',
        paddingBottom: '18px',
        paddingTop: '28px'
      }} data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
          <Grid data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
            <Typography translateGroup="form-deposit" translateKey="Käyttöehdot" color="#000" data-sentry-element="Typography" data-sentry-source-file="amountForm.tsx" />
          </Grid>
          <Grid horizontalAlgin="flex-end" data-sentry-element="Grid" data-sentry-source-file="amountForm.tsx">
            <Zimpler data-sentry-element="Zimpler" data-sentry-source-file="amountForm.tsx" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
}