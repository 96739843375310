/* eslint-disable react/prop-types */
import React from 'react';
export default function AlertIcon({
  width = 99,
  height = 99,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props} data-sentry-element="svg" data-sentry-component="AlertIcon" data-sentry-source-file="alertIcon.tsx">
      <g clipPath="url(#clip0_9617_4664)" data-sentry-element="g" data-sentry-source-file="alertIcon.tsx">
        <path d="M10 14.375C9.48125 14.375 9.0625 14.7938 9.0625 15.3125C9.0625 15.8312 9.48125 16.25 10 16.25C10.5187 16.25 10.9375 15.8312 10.9375 15.3125C10.9375 14.7938 10.5187 14.375 10 14.375Z" fill="#4A26FF" data-sentry-element="path" data-sentry-source-file="alertIcon.tsx" />
        <path d="M10.625 7.5H9.375V13.125H10.625V7.5Z" fill="#4A26FF" data-sentry-element="path" data-sentry-source-file="alertIcon.tsx" />
        <path d="M18.125 18.75H1.875C1.53125 18.75 1.25 18.4687 1.25 18.125C1.25 18.025 1.275 17.925 1.31875 17.8375L9.44375 2.21249C9.6 1.90624 9.98125 1.78749 10.2875 1.94374C10.4 1.99999 10.4938 2.09374 10.5563 2.21249L18.6813 17.8375C18.8375 18.1437 18.7188 18.5187 18.4125 18.6812C18.325 18.725 18.225 18.75 18.125 18.75ZM2.90625 17.5H17.0938L10 3.85624L2.90625 17.5Z" fill="#4A26FF" data-sentry-element="path" data-sentry-source-file="alertIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="alertIcon.tsx">
        <clipPath id="clip0_9617_4664" data-sentry-element="clipPath" data-sentry-source-file="alertIcon.tsx">
          <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="alertIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
}