import Grid from 'components/uiKit/grid';
import React, { useContext } from 'react';
import Card from 'components/uiKit/card';
import { DepositSectionContext } from '..';
export default function Cashier() {
  const {
    paymentUrl
  } = useContext(DepositSectionContext);
  return <Card style={{
    height: '800px',
    maxHeight: 'calc(100dvh - 90px)',
    borderRadius: '8pt',
    overflow: 'hidden'
  }} data-sentry-element="Card" data-sentry-component="Cashier" data-sentry-source-file="cashier.tsx">
            <iframe src={paymentUrl || ''} title="payments" style={{
      width: '100%',
      height: '100%',
      border: 0
    }} />
        </Card>;
}