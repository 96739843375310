import { twMerge } from 'tailwind-merge';
import CloseIcon from 'assets/icons/closeIcon';
import { UiCloseButtonProps } from './types';
export function UiCloseButton({
  onClick,
  variant = 'dark',
  className,
  iconWidth = 28,
  iconHeight = 28,
  size = 'md'
}: UiCloseButtonProps) {
  const classNames = twMerge(size === 'md' ? 'w-[78px] h-[78px]' : 'w-10 h-10', 'flex justify-center items-center cursor-pointer', variant === 'dark' ? 'text-primary hover:bg-primary-100 focus:bg-primary-100 active:bg-close-dark-active active:text-white' : 'text-white hover:bg-white-100 focus:bg-white-100 active:bg-white-100 active:text-transparent', className);
  return <div className={classNames} onClick={onClick} data-sentry-component="UiCloseButton" data-sentry-source-file="CloseButton.tsx">
      <CloseIcon width={size === 'md' ? iconWidth : 20} height={size === 'md' ? iconHeight : 20} data-sentry-element="CloseIcon" data-sentry-source-file="CloseButton.tsx" />
    </div>;
}