export function formatCurrency(number: number, currency: string): string {
  if (currency) {
    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
      currencyDisplay: 'code'
    }).format(number);
    return formattedNumber;
  }
  return '';
}
const currencySymbols: {
  [key: string]: string;
} = {
  USD: '$',
  // US Dollar
  EUR: '€',
  // Euro
  GBP: '£',
  // British Pound
  JPY: '¥',
  // Japanese Yen
  AUD: 'A$',
  // Australian Dollar
  CAD: 'C$',
  // Canadian Dollar
  CHF: 'CHF',
  // Swiss Franc
  CNY: '¥',
  // Chinese Yuan
  SEK: 'kr',
  // Swedish Krona
  NZD: 'NZ$',
  // New Zealand Dollar
  MXN: 'Mex$',
  // Mexican Peso
  SGD: 'S$',
  // Singapore Dollar
  HKD: 'HK$',
  // Hong Kong Dollar
  NOK: 'kr',
  // Norwegian Krone
  KRW: '₩',
  // South Korean Won
  TRY: '₺',
  // Turkish Lira
  RUB: '₽',
  // Russian Ruble
  INR: '₹',
  // Indian Rupee
  BRL: 'R$',
  // Brazilian Real
  ZAR: 'R',
  // South African Rand
  PHP: '₱',
  // Philippine Piso
  PLN: 'zł',
  // Polish Złoty
  DKK: 'kr',
  // Danish Krone
  MYR: 'RM',
  // Malaysian Ringgit
  THB: '฿',
  // Thai Baht
  IDR: 'Rp',
  // Indonesian Rupiah
  HUF: 'Ft',
  // Hungarian Forint
  CZK: 'Kč',
  // Czech Koruna
  ILS: '₪',
  // Israeli New Shekel
  CLP: '$',
  // Chilean Peso
  PEN: 'S/.',
  // Peruvian Sol
  AED: 'د.إ',
  // United Arab Emirates Dirham
  COP: '$',
  // Colombian Peso
  SAR: '﷼',
  // Saudi Riyal
  QAR: 'ق.ر',
  // Qatari Riyal
  KWD: 'د.ك' // Kuwaiti Dinar
};
export function formatCurrencySymbol(number: number, currency: string, decimal?: boolean): string {
  if (currency) {
    // Format the number without currency first
    const formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimal ? 2 : 0,
      maximumFractionDigits: decimal ? 2 : 0
    }).format(number);
    return `${formattedNumber} ${currencySymbols[currency.toUpperCase()] || currency.toUpperCase()}`;
  }
  return '';
}