import React, { createContext, useContext, useEffect, useState } from 'react';
import Grid from 'components/uiKit/grid';
import Loading from 'assets/loading';
import { tuohiLaunchLogin } from 'utils/services/api/requests/userEnv';
import { useQuery } from 'react-query';
import AuthContext from 'context/auth';
import { getBoundaries } from 'utils/services/api/requests/wallet';
import { useRouter } from 'next/router';
import GlobalContext from 'context/global';
import { gaAnalytics } from 'utils/functions/track/gaAnalytics';
import { useProfile } from 'utils/customHooks/useProfile';
import AmountForm from './steps/amountForm';
import { BoundariesProps, DepositSectionContextI, DepositSectionProps, DepositSectionSteps } from './types';
import Cashier from './steps/cashier';
export const DepositSectionContext = createContext<DepositSectionContextI>({
  step: 'form',
  setStep: (step: DepositSectionSteps) => {},
  value: '',
  setValue: (value: string) => {},
  paymentUrl: '',
  setPaymentUrl: (paymentUrl: string) => {},
  boundaries: null
});
export default function DepositSection(props: DepositSectionProps) {
  const {
    amount: amountP
  } = props;
  const router = useRouter();
  const {
    appType
  } = useContext(GlobalContext);
  const {
    profile
  } = useProfile();
  const [step, setStep] = useState<DepositSectionSteps>(amountP ? 'loading-cashier' : 'form');
  const {
    token
  } = useContext(AuthContext);
  const {
    data: boundaries
  } = useQuery(['user-boundaries', token], getBoundaries);
  const boundariesConvert = (limits: BoundariesProps | null) => limits ? {
    minDeposit: limits.minDeposit / 100,
    maxDeposit: limits.maxDeposit / 100,
    minWithdrawal: limits.minWithdrawal / 100,
    maxWithdrawal: limits.maxWithdrawal / 100
  } : null;
  const [value, setValue] = useState<string>(amountP || '100');
  const [paymentUrl, setPaymentUrl] = useState('');
  async function startCashier() {
    const amount = parseInt(`${parseFloat(value) * 100}`, 10);
    gaAnalytics.trackEvent({
      action: 'deposit',
      category: 'transaction',
      label: 'start_deposit',
      value: amount,
      userId: profile?.id
    });
    await tuohiLaunchLogin({
      depositAmount: amount
    }).then((res: any) => {
      if (res) {
        setPaymentUrl(res.userFormUrl);
        if (appType === 'mobile') {
          router.push(res.userFormUrl);
        } else {
          setStep('cashier');
        }
      }
    });
  }
  useEffect(() => {
    if (step === 'loading-cashier') {
      startCashier();
    }
  }, [step]);
  return <DepositSectionContext.Provider value={{
    step,
    setStep,
    value,
    setValue,
    paymentUrl,
    setPaymentUrl,
    boundaries: boundariesConvert(boundaries)
  }} data-sentry-element="unknown" data-sentry-component="DepositSection" data-sentry-source-file="index.tsx">
      <Grid horizontalAlgin="center" verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {step === 'form' && <AmountForm />}
        {step === 'loading-cashier' && <Grid verticalAlgin="center" horizontalAlgin="center">
            <Loading />
          </Grid>}
        {step === 'cashier' && <Cashier />}
      </Grid>
    </DepositSectionContext.Provider>;
}