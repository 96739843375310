import { api } from '../..';
import urls from '../urls';
export async function getBalance(data: any) {
  return api.get(urls.wallet.getBalance).then(response => response.data).catch(err => null);
}
export async function getBoundaries(data: any) {
  return api.get(urls.wallet.getBoundaries).then(response => response.data).catch(err => null);
}
interface cashierLaunch {
  amountInCents: number;
  intent: 'payment' | 'withdrawal';
  bonusCode?: string | undefined | null;
  displayKeyword?: string | undefined | null;
}
export function getUrlPayment(data: cashierLaunch) {
  return api.post(urls.wallet.getPaymentFrameUrl, data);
}
export async function getWalletTransactions(currency: string) {
  try {
    const response = await api.get(`${urls.wallet.getTransactions}/${currency}`);
    return response.data;
  } catch (err) {
    return null;
  }
}
export async function getBetTransactions(currency: string) {
  try {
    const response = await api.get(`${urls.wallet.getBetTransactions}/${currency}`);
    return response.data;
  } catch (err) {
    return null;
  }
}
export async function getTransactionStatus(transactionID: string) {
  try {
    const response = await api.get(`${urls.wallet.getTransactionStatus}/${transactionID}`);
    return response.data;
  } catch (err) {
    return null;
  }
}
export interface PaymentGatewayI {
  displayKeyword: string;
  isDeposit: boolean;
  isWithdraw: boolean;
  logo: string;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
}
export async function getPaymentMethods() {
  try {
    const response = await api.get(urls.wallet.getPaymentMethods);
    return response.data;
  } catch (err) {
    return null;
  }
}
export interface WageringResponse {
  remainingWager: number;
  error?: any;
}
export async function getWageringRequirements(): Promise<WageringResponse> {
  try {
    const response = await api.get(urls.freeSpins.wageringLeft);
    return {
      remainingWager: response.data
    };
  } catch (err) {
    return {
      remainingWager: 0,
      error: err
    };
  }
}