/* eslint-disable react/prop-types */
import React from 'react';
export default function WrenchIcon({
  width = 99,
  height = 99,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="WrenchIcon" data-sentry-source-file="wrenchIcon.tsx">
      <g clipPath="url(#clip0_9632_6851)" data-sentry-element="g" data-sentry-source-file="wrenchIcon.tsx">
        <path d="M37.4348 6.1875C31.4948 6.1875 25.6786 7.88906 20.7286 11.1375L40.5286 30.9375C43.2511 33.2578 43.5914 37.3725 41.2401 40.095C41.2092 40.1259 41.1783 40.1878 41.1473 40.2187C38.4867 42.6009 34.372 42.3844 31.9589 39.7237C31.9279 39.6928 31.897 39.6309 31.8661 39.6L11.4473 19.8C7.92044 24.9975 6.09513 31.1541 6.18794 37.4344C6.24982 54.6666 20.2026 68.6194 37.4348 68.6812C40.1573 68.6812 42.8489 68.3719 45.4786 67.7531L66.2067 88.4812C72.2704 94.545 82.1086 94.545 88.1723 88.4812C94.2361 82.4175 94.2361 72.5794 88.1723 66.5156L67.4442 45.7875C68.0629 43.1578 68.3723 40.4662 68.3723 37.7437C68.7126 20.6662 55.1311 6.52781 38.0536 6.1875C37.837 6.1875 37.6514 6.1875 37.4348 6.1875ZM62.1848 37.4344C62.1848 39.6309 61.8754 41.8275 61.2567 43.9312L60.3286 47.3344L62.8036 49.8094L83.5317 70.5375C85.3261 72.2391 86.3161 74.5903 86.3161 77.0344C86.3779 79.5094 85.357 81.8916 83.5317 83.5312C80.1286 87.12 74.4361 87.2437 70.8473 83.8406C70.7545 83.7478 70.6307 83.6241 70.5379 83.5312L49.8098 62.8031L47.3348 60.3281L43.9317 61.2562C41.8279 61.875 39.6314 62.1844 37.4348 62.1844C30.8761 62.1844 24.5339 59.6166 19.8004 55.0687C14.9433 50.49 12.2517 44.0859 12.3754 37.4344C12.3754 35.145 12.6848 32.8556 13.3036 30.6281L26.9161 44.2406C31.7114 49.4381 39.817 49.7784 45.0145 45.0141C45.0764 44.9522 45.1073 44.9212 45.1692 44.8594C50.0264 39.7237 49.8408 31.6181 44.7051 26.7609C44.6433 26.6991 44.6123 26.6681 44.5504 26.6062L30.9379 12.9937C32.9489 12.375 35.0217 12.0347 37.1254 12.0656C43.6842 12.0656 50.0264 14.6334 54.7598 19.1812C59.5242 24.0694 62.1848 30.6281 62.1848 37.4344Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="wrenchIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="wrenchIcon.tsx">
        <clipPath id="clip0_9632_6851" data-sentry-element="clipPath" data-sentry-source-file="wrenchIcon.tsx">
          <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="wrenchIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
}