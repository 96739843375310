export const parseAmountValue = (vl: string | number) => {
  let inputValue = `${vl}`

  inputValue = inputValue.replace(/[^0-9.]/g, '')
  if (inputValue.includes('.')) {
    const parts = inputValue.split('.')
    if (parts[1].length > 2) {
      inputValue = parseFloat(inputValue).toFixed(2)
    }
  }
  return inputValue
}

export const isValidDepositAmount = (price: number) => {
  return price >= 1 && price <= 10000
}
